import React, { useContext, useEffect, useRef, useState } from "react";
import { invalidate, useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { EquirectangularRefractionMapping, Group, MeshStandardMaterial, TextureLoader } from "three";
import envMapImage from "../assets/envMap.jpg";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import { CollabContext } from "../CollabProvider";
import { ViewRControlsRef } from "./ViewRControls";
// import MeasurementTool from "./MeasurementTool";

export default function Model({ modelCode, enableMeasure, controlsRef }: { modelCode: string | undefined; enableMeasure: boolean; controlsRef: React.RefObject<ViewRControlsRef> }) {
  const gltf = useLoader(GLTFLoader, `${process.env.REACT_APP_MODELS_SERVER}/download/${modelCode}`, loader => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco-gltf/");
    (loader as GLTFLoader).setDRACOLoader(dracoLoader);
  });
  const envMap = useLoader(TextureLoader, envMapImage);
  useEffect(() => {
    envMap.mapping = EquirectangularRefractionMapping;
    gltf.scene.traverse(o => {
      const mat = (o as any).material as MeshStandardMaterial | undefined;
      if (mat) {
        mat.envMap = envMap;
      }
      o.castShadow = true;
      o.receiveShadow = true;
    });
    setOriginalScene(x => x === undefined ? gltf.scene.clone(true) : x);
  }, [gltf, envMap]);

  const sceneRef = useRef<Group>(null);
  const [originalScene, setOriginalScene] = useState<Group>();
  const collabContext = useContext(CollabContext);
  useEffect(() => {
    if (!sceneRef.current || !originalScene) return;
    if (collabContext.connectCollab) {
      if (sceneRef.current.children[0]?.id !== gltf.scene.id || sceneRef.current.children.length !== 1) {
        sceneRef.current.remove(...sceneRef.current.children);
        sceneRef.current.add(gltf.scene);
        invalidate();
        setTimeout(() => {
          controlsRef.current?.fitCameraToModel();
        }, 10);
      }
    } else {
      if (sceneRef.current.children[0]?.id !== originalScene.id || sceneRef.current.children.length !== 1) {
        sceneRef.current.remove(...sceneRef.current.children);
        sceneRef.current.add(originalScene);
        invalidate();
        setTimeout(() => {
          controlsRef.current?.fitCameraToModel();
        }, 10);
      }
    }
  }, [collabContext.connectCollab, gltf, originalScene, controlsRef]);

  useEffect(() => {
    if (!collabContext.connectCollab || !originalScene) return;

    const sub = collabContext.syncWebData$.subscribe(syncWebData => {
      if (syncWebData.status === "active") {
        for (const node of syncWebData.data.nodesList) {
          const object = gltf.scene.getObjectByName(node.id);
          if (!object) continue;
          if (object.visible !== node.isvisible) {
            object.visible = node.isvisible;
            invalidate();
          }
          if (node.transform?.position && (
            object.position.x !== node.transform.position.x ||
            object.position.y !== node.transform.position.z ||
            object.position.z !== -node.transform.position.y
          )) {
            const { x, y, z } = node.transform.position;
            object.position.set(x, z, -y);
            invalidate();
          }
          if (node.transform?.rotation && (
            object.quaternion.x !== node.transform.rotation.x ||
            object.quaternion.y !== node.transform.rotation.z ||
            object.quaternion.z !== -node.transform.rotation.y ||
            object.quaternion.w !== node.transform.rotation.w
          )) {
            const { x, y, z, w } = node.transform.rotation;
            object.quaternion.set(x, z, -y, w);
            invalidate();
          }
          if (node.transform?.scale && (
            object.scale.x !== node.transform.scale.x ||
            object.scale.y !== node.transform.scale.z ||
            object.scale.z !== node.transform.scale.y
          )) {
            const { x, y, z } = node.transform.scale;
            object.scale.set(x, z, y);
            invalidate();
          }
        }
      }
    });
    return () => {
      sub.unsubscribe();
    };
  }, [collabContext.connectCollab, collabContext.syncWebData$, gltf.scene, originalScene]);

  return (
    <>
      <group
        ref={sceneRef}
        rotation={[0, 0, 0]}
      />
      {/* <MeasurementTool
        objects={gltf.scene.children}
        takingMeasurement={enableMeasure}
      /> */}
    </>
  );
}
