import React, { Suspense, useContext, useEffect, useImperativeHandle, useRef } from "react";
import { Box as MUIBox, CircularProgress } from "@mui/material";
import { Canvas, useThree } from "@react-three/fiber";
import { CameraHelper, Color, DirectionalLight, Object3D } from "three";
import Grid from "./Grid";
import { CollabContext } from "../CollabProvider";
import ViewRControls, { ViewRControlsRef } from "./ViewRControls";
import Model from "./Model";
import RenderEffects from "./RenderEffects";

export type Viewport3DRef = {
  fitCameraToModel: (fitFac?: number) => void;
};

function MyLights() {
  const lightRef = useRef<DirectionalLight>(null);
  const { scene } = useThree();
  useEffect(() => {
    if (!lightRef.current) return;
    lightRef.current.target.updateMatrixWorld();
    lightRef.current.shadow.camera.top = 7;
    lightRef.current.shadow.camera.bottom = -5;
    lightRef.current.shadow.camera.right = 3;
    lightRef.current.shadow.bias = -.0005
    // scene.add(new CameraHelper(lightRef.current.shadow.camera));
  }, [scene]);
  return (
    <>
      <directionalLight
        ref={lightRef}
        castShadow
        position={[-5, 10, 0]}
        target-position={[-2, 0, 0]}
        intensity={0.25}
      />
      <ambientLight color={new Color(1, 0.95, 0.95)} intensity={0.5} />
    </>
  );
}

type Viewport3DProps = {
  modelCode: string | undefined;
  enableGrid: boolean;
  enableAO: boolean;
  enableMeasure: boolean;
};

const Viewport3D = React.forwardRef<Viewport3DRef, Viewport3DProps>(({ modelCode, enableGrid, enableAO, enableMeasure }: Viewport3DProps, ref) => {
  const controlsRef = useRef<ViewRControlsRef>(null);
  const modelGroupRef = useRef<Object3D>(null);
  useImperativeHandle(ref, () => ({
    fitCameraToModel: () => controlsRef.current?.fitCameraToModel(),
  }));
  const collabContext = useContext(CollabContext);

  return (
    // @ts-ignore
    <MUIBox
      // @ts-ignore
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Suspense fallback={<CircularProgress color="secondary" />}>
        <Canvas
          gl={{
            logarithmicDepthBuffer: false,
            antialias: false,
          }}
          onCreated={state => {
            state.gl.autoClear = false;
          }}
          frameloop="demand"
          flat
          linear
          style={{
            touchAction: "none",
          }}
          dpr={window.devicePixelRatio ?? 1}
          shadows
        >
          <CollabContext.Provider value={collabContext}>
            <RenderEffects enabled={enableAO} />
            <ViewRControls
              ref={controlsRef}
              modelGroupRef={modelGroupRef}
            />
            <group ref={modelGroupRef}>
              <Model
                modelCode={modelCode}
                enableMeasure={enableMeasure}
                controlsRef={controlsRef}
              />
            </group>
            <Grid visible={enableGrid} />
            <MyLights />
          </CollabContext.Provider>
        </Canvas>
      </Suspense>
    </MUIBox>
  );
});

export default Viewport3D;
