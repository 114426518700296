import React, { createContext, useEffect, useMemo, useState } from "react";
import { AuthenticationClient } from "@cmckiesel/node-protos/proto_web_compiled/AuthenticationService_grpc_web_pb";
import { Empty } from "google-protobuf/google/protobuf/empty_pb";
import { Metadata } from "grpc-web";
import { Cookie, Cookies, Credentials } from "@cmckiesel/node-protos/proto_web_compiled/AuthenticationService_pb";
import { RegistryClient } from "@cmckiesel/node-protos/proto_web_compiled/RegistryService_grpc_web_pb";
import { SynchronizationWebData } from "@cmckiesel/node-protos/proto_web_compiled/RegistryService_pb";
import { BehaviorSubject } from "rxjs";
import { encode } from "base64-arraybuffer";

const emptyMetadata: Metadata = {};

const authenticate = async () => {
  const authClient = new AuthenticationClient(process.env.REACT_APP_COLLAB_SERVER!);
  const authCredentials = new Credentials();
  authCredentials.setValue("WebViewR");

  const cookie = await new Promise<Cookie>((res, rej) => {
    authClient.authenticate(authCredentials, emptyMetadata, (error, value) => {
      if (error || !value) {
        rej(error);
      } else {
        res(value);
      }
    });
  });

  const cookies = new Cookies();
  cookies.setValueList([cookie]);

  return encode(cookies.serializeBinary());
};

const getSynchronizationWebData = async (cookiesBin: string, modelCode: string) => {
  const metadata: Metadata = {
    "cookies-bin": cookiesBin,
    "SessionID": modelCode,
    "SessionPassword": "",
  };
  const client = new RegistryClient(process.env.REACT_APP_COLLAB_SERVER!, undefined, metadata);

  const syncWebData = await new Promise<SynchronizationWebData>((res, rej) => {
    client.getSynchronizationWebData(new Empty(), metadata, (error, value) => {
      if (error || !value) {
        rej(error);
      } else {
        res(value);
      }
    });
  });

  return syncWebData.toObject();
};

type SyncWebData = {
  status: "unknown";
} | {
  status: "inactive";
} | {
  status: "active";
  data: SynchronizationWebData.AsObject;
};

export const CollabContext = createContext<{
  syncWebData$: BehaviorSubject<SyncWebData>;
  connectCollab: boolean;
  setConnectCollab: (c: boolean) => void;
}>({
  syncWebData$: new BehaviorSubject<SyncWebData>({ status: "unknown" }),
  connectCollab: false,
  setConnectCollab: (c: boolean) => { },
});

export default function CollabProvider({ children, modelCode }: { children: React.ReactNode; modelCode: string | undefined }) {
  const [cookiesBin, setCookiesBin] = useState<string>();
  useEffect(() => {
    authenticate().then(c => setCookiesBin(c));
  }, []);

  const syncWebData$ = useMemo(() => new BehaviorSubject<SyncWebData>({
    status: "unknown",
  }), []);

  const [connectCollab, setConnectCollab] = useState(false);
  useEffect(() => {
    if (cookiesBin === undefined || modelCode === undefined) {
      if (syncWebData$.getValue().status !== "unknown") {
        syncWebData$.next({ status: "unknown" });
      }
      return;
    }
    let keepAlive = true;
    const poll = async () => {
      try {
        const data = await getSynchronizationWebData(cookiesBin, modelCode);
        syncWebData$.next({
          status: "active",
          data,
        });
        setConnectCollab(true);
      } catch (e) {
        if (syncWebData$.getValue().status !== "inactive") {
          syncWebData$.next({
            status: "inactive"
          });
          setConnectCollab(false);
        }
      }
      if (keepAlive) {
        await new Promise(res => setTimeout(res, connectCollab ? 100 : 2000));
        requestAnimationFrame(poll);
      }
    };
    poll();
    return () => {
      keepAlive = false;
    };
  }, [cookiesBin, syncWebData$, connectCollab, modelCode]);

  return (
    <CollabContext.Provider value={{
      syncWebData$,
      connectCollab,
      setConnectCollab,
    }}>
      {children}
    </CollabContext.Provider>
  );
}
