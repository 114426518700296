import { Box, Button, Card, CardContent, CardHeader, CircularProgress, Paper, Stack, TextField, ThemeProvider, useMediaQuery, useTheme } from "@mui/material";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router";
import useThemeOptions from "../useThemeOptions";
import hirschImage from "../assets/pCon_presenter_Icon.png";
import { SnackbarProvider, useSnackbar } from "notistack";

function HomePageContent() {
  const [modelCode, setModelCode] = useState("");
  const [requesting, setRequesting] = useState(false);
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Paper
      // @ts-ignore
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "0",
        p: 5,
      }}
    >
      <Card
        variant="outlined"
        sx={{
          height: "100%",
          maxHeight: "500px",
          width: "100%",
          maxWidth: "600px",
        }}
      >
        <CardContent
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            p: 0,
          }}
        >
          <Stack direction={downMd ? "column" : "row"} sx={{ width: "100%", height: "100%" }}>
            {/* @ts-ignore */}
            <Box sx={{ m: 2, flexGrow: 1, backgroundImage: `url(${hirschImage})`, backgroundSize: "contain", backgroundPosition: "center", backgroundRepeat: "no-repeat" }} />
            <Stack sx={{ p: 2 }} justifyContent="center">
              <CardHeader
                title="Enter Model Code"
                sx={{
                  textAlign: downMd ? "center" : undefined,
                }}
              />
              <form
                onSubmit={async (e: FormEvent) => {
                  e.preventDefault();
                  setRequesting(true);
                  try {
                    const response = await (await fetch(`${process.env.REACT_APP_MODELS_SERVER}/check/${modelCode}`)).text();
                    if (response === "false") throw new Error();
                    navigate(`/model/${modelCode}`);
                  }
                  catch {
                    enqueueSnackbar("Unknown Model Code!", { variant: "error" });
                    setModelCode("");
                  }
                  finally {
                    setRequesting(false);
                  }
                }}
              >
                <Stack spacing={2} alignItems="center">
                  <TextField
                    label="Model Code"
                    value={modelCode}
                    onChange={e => setModelCode(e.target.value.toUpperCase())}
                    color="secondary"
                    sx={{
                      "& input": {
                        textAlign: "center",
                        textTransform: "uppercase",
                      },
                    }}
                  />
                  {
                    requesting ? <CircularProgress /> : (
                      <Button
                        type="submit"
                        variant="outlined"
                        color="secondary"
                        disabled={modelCode === ""}
                      >
                        Submit
                      </Button>
                    )
                  }
                </Stack>
              </form>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Paper>
  );
}

export default function HomePage() {
  const themeOptions = useThemeOptions();
  return (
    <ThemeProvider theme={themeOptions}>
      <SnackbarProvider maxSnack={3}>
        <HomePageContent />
      </SnackbarProvider>
    </ThemeProvider>
  );
}
